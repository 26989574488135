<template>
    <span>
        <v-app-bar fixed color="green">
            <v-app-bar-nav-icon v-if="isLoggedIn()" @click="$refs.slideMenu.showMenu()" class="mx-xs-0 mx-md-3"></v-app-bar-nav-icon>

            <v-toolbar-title class="px-0 px-sm-1 px-md-1">
                <a href="/">
                    <div style="display: flex; align-items:center;" class="d-none d-md-flex">
                        <img src="/images/PeekPicaClear.png" style="height:50px; margin-right:10px;">
                        <h1> Chime Channel </h1>
                    </div>
                    <div style="display: flex; align-items:center;" class="d-flex d-md-none">
                        <img src="/images/PeekPicaClear.png" style="height:30px; margin-right:10px;">
                        <h3> Chime Channel </h3>
                    </div>
                </a>
            </v-toolbar-title>

            <!-- <div style="display:flex;">
                <div class="d-flex d-sm-none" style="height: 100%; color:white"> EXTRA SMALL </div>
                <div class="d-none d-sm-flex d-md-none" style="height: 100%; color:white"> SMALL </div>
                <div class="d-none d-md-flex d-lg-none" style="height: 100%; color:white"> MEDIUM </div>
                <div class="d-none d-lg-flex d-xl-none" style="height: 100%; color:white"> LARGE </div>
                <div class="d-none d-xl-flex" style="height: 100%; color:white"> EXTRA LARGE </div>
            </div> -->
            
            <v-spacer></v-spacer>

            <div v-if="!isLoggedIn()">
                <span v-if="mobilenative">
                    <v-btn @click="handleMobileLogin" color="white" elevation="0" class="d-none d-sm-flex"> Mobile Sign In </v-btn>
                    <!-- <span>
                        mobiletoken: {{mobiletoken}}
                    </span> -->
                    <v-btn @click="handleMobileLogin" color="white" elevation="0" class="d-flex d-sm-none" x-small > Mobile Sign In </v-btn>
                </span>
                <span v-if="!mobilenative">
                    <v-btn @click="handleLogin" color="white" elevation="0" class="d-none d-sm-flex"> Sign In </v-btn>
                    <v-btn @click="handleLogin" color="white" elevation="0" class="d-flex d-sm-none" x-small > Sign In </v-btn>
                </span>
            </div>
            <div class="mr-0 ml-0 container-container" v-if="isLoggedIn()">
                <div class="col-12 col-sm-6 py-1 icons-container">
                    <NotificationsButton />

                    <v-menu open-on-click offset-y transition="slide-x-transition" bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" fab v-on="on" x-small class="mx-1 mx-sm-2 mx-md-3">
                                <span v-if="avatarUrl">
                                    <img :src="avatarUrl" class="header-avatar" style="" />
                                </span>
                                <span v-if="!avatarUrl">
                                    <CustomIcon glyph="mdi-account" /> 
                                </span>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item router to="/sys/profile">
                                <v-list-item-action>
                                    <v-list-item-title>Profile</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item router to="/sys/friends">
                                <v-list-item-action>
                                    <v-list-item-title>Friends</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="handleLogout">
                                <v-list-item-action>
                                    <v-list-item-title>Sign Out</v-list-item-title>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div class="col-12 col-sm-6 d-none d-sm-flex py-1 name-container">
                    <!-- <span class="ml-2 mx-sm-3 mx-md-4"> -->
                        <a :href="domain + '/' +  channelName" style="color:white">
                            {{channelName}}
                        </a>
                    <!-- </span> -->
                </div>
            </div>
        </v-app-bar>
        <SlideMenu ref="slideMenu" />
    </span>
</template>
<script>
import auth from "/src/utils/auth";
import api from '/src/utils/api';

import invoke from 'react-native-webview-invoke/browser';
const one_minute = 60000;

export default {
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
        SlideMenu: () => import("/src/components/SlideMenu"),
        NotificationsButton: () => import("/src/components/NotificationsButton"),
    },
    data() {
        return {
            channelName: null,
            avatarUrl: null,
            domain: window.location.origin,
            mobilenative: false,
            isLoggedIn: auth.isLoggedIn,
            // mobiletoken: null,
        };
    },
    mounted() {
        //setTimeout(this.logoutIfExpired, one_minute * 10);
        this.tokenStatusCheck();
        // this.logoutIfExpired();
        this.channelName = localStorage.getItem('primaryChannelName');
        this.avatarUrl = localStorage.getItem('avatarUrl');

        document.addEventListener("channel-name-change", e => {
            this.channelName = e.detail;
        });
        document.addEventListener("avatar-change", e => {
            this.avatarUrl = e.detail;
        });

        if (this.$route.query.device_token) {
            console.log("header deviceToken",this.$route.query.device_token);
            api.getCall('consolelog?logText=header deviceToken-' + this.$route.query.device_token)
            localStorage.setItem('deviceToken', this.$route.query.device_token);
        }

        // console.log("this.$route.query.mobilenative",this.$route.query.mobilenative);
        // // On mobile, the login mechanism is handled outside of the browser.
        // if (this.$route.query.mobilenative == 'true') {
        //     this.mobilenative = true;
        //     if (this.$route.query.access_token)
        //         setAccessToken();
        //     if (this.$route.query.id_token)
        //         setIdToken();
        // }
    },
    methods: {
        handleLogin() {
            auth.login();
            // console.log("isLoggedIn", isLoggedIn())
        },
        handleLogout() {
            auth.logout();
        },
        // We can utilize the login screen on the native mobile app but this turns out to be unnecessary for now.
        async handleMobileLogin() {
            let mobileValue = await invoke.fn.doMobileLogin();
            console.log("mobileValue", mobileValue);
        },
        // logoutIfExpired() {
        //     var idToken = auth.getIdToken();
        //     if (idToken) {
        //         // console.log("User has idtoken");

        //         // Log the user out if the token is expired. 
        //         if (auth.isTokenExpired(idToken)) {
        //             // console.log("Needs to be logged out");
        //             auth.logout();
        //             window.location.reload();
        //         }
        //         else {
        //             // console.log("Not expired yet");
        //             setTimeout(this.logoutIfExpired, one_minute * 10);
        //         }
        //     }
        //     // else {
        //     //   console.log("No token");
        //     // }

        // },
        tokenStatusCheck() {
            let idToken = auth.getIdToken();
            let accessToken = auth.getAccessToken();

            if (idToken) {
                // Log the user out if the ID token is expired. 
                if (auth.isTokenExpired(idToken)) {
                    // console.log("Needs to be logged out");
                    auth.logout();
                    window.location.reload();
                }
                else {
                    // console.log("Getting new access token.");
                    // Access tokens last a day and ID tokens last a month. We can use ID token to get a new access token.
                    if (auth.isTokenExpired(accessToken)) {
                        // console.log("access token expired");
                        auth.login();
                    }

                    setTimeout(this.tokenStatusCheck, one_minute * 10);
                }
            }
        },
    },
};
</script>
<style scoped>
h1, h2, h3, h5 {
  color:white;
  font-family:Calibri, Georgia, Arial;
  white-space: nowrap;
}
a {
  color:white;
  text-decoration: none;
}

.v-app-bar {
    height:57px !important;
}

.header-avatar {
    height:40px; width:40px;
    border: 2px solid white;
    border-radius: 20px;
    position:relative;
    top:1px;
}

.container-container {
    display:flex;
    width: 120px;
    position: relative;
    left: 10px;
}
.icons-container {
    display:flex;
    justify-content: right;
    align-items:center;
}

.name-container {
    display:flex;
    justify-content: left;
    align-items:center;
}

@media (min-width: 600px) {
    .container-container {
        display:flex;
        width: 250px;
        position:static;
    }
}

@media (min-width: 960px) {
    .header-avatar {
        height:50px; width:50px;
        border-radius: 25px;
    }

    .v-app-bar {
        height:64px !important;
    }
    .name-container {
        justify-content: left;
        align-items:center;
    }
}
</style>